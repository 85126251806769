
import request from "@/utils/request";


// 通知公告列表
export function getNoticeList(data) {
  return request({
    url: '/wxapp/notice/list',
    method: 'get',
    data
  })
}

// 通知公告详情
export function getNoticeDetail(id) {
  return request({
    url: `/wxapp/notice/detail?noticeId=${id}`,
    method: 'get'
  })
}