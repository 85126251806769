<!--
 * @version: 20220211
 * @Author: zgy
 * @Date: 2022-08-03 14:30:54
 * @LastEditors: zgy
 * @LastEditTime: 2022-08-04 13:25:04
 * @Description: description
-->
<template>
  <div class="notice-container">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="order-list-refresh">
      <van-list
        class="order-flow"
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        @offset="10"
      >
        <div class="item-list">
          <router-link :to="'/notice-detail/'+item.noticeId" v-for="(item, index) in list" :key="index" class="label rowbetween">
            <div class="rowCenter item-title">
              <span>{{item.noticeTitle}}</span>
            </div>
            <div class="right-cont">
              <span class="time">{{item.timeStr}}</span>
              <div class="more"></div>
            </div>
          </router-link>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { getNoticeList } from '@/api/notice'
import { parseTime } from '@/utils/index'
export default {
  name:'notice',
  setup() {
    const router = useRouter()

    const state = reactive({
      finished: false,
      refreshing: false,
      totalPage:0,
      list: [],
      loading: false,
      queryParams: {    // 查询参数
        pageNum: 1,
        pageSize: 10
      },
    })

    //列表
    const loadData = () => {        
      getNoticeList(state.queryParams).then(res => {
        if(res && res.rows){
          const data = res.rows;
          for(let i=0,len=data.length; i<len; i++){
            data[i].timeStr =  parseTime(data[i].createTime,'{y}-{m}-{d}'); 
          }
          state.list = state.list.concat(data)
          state.totalPage = res.pageCount;
          state.loading = false;
          if (state.queryParams.pageNum >= res.pageCount) state.finished = true
        }
      });
    }
    //加载数据
    const onLoad = () => {
      if (!state.refreshing && state.queryParams.pageNum < state.totalPage) {
        // console.log(state.queryParams.pageNum)
        // console.log(state.totalPage)
        state.queryParams.pageNum = state.queryParams.pageNum + 1
      }
      if (state.refreshing) {
        state.list = [];
        state.refreshing = false;
      }
      loadData();
    }
    //下拉刷新
    const onRefresh = () => {
      state.refreshing = true
      state.finished = false
      state.loading = true
      state.queryParams.pageNum = 1
      onLoad();
    }

    return {
      ...toRefs(state),
      onLoad,
      onRefresh
    }
  }
};
</script>

<style lang="less" scoped>
.notice-container{
  background-color: #fff;
  width: 100%;
  height: 100vh;
}
.order-list-refresh{
  width: 100%;
  height: 100vh;

}
.item-list{
  border-top: solid 1px #f2f2f2;
  .label{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: solid 1px #f2f2f2;
    height: 49px;
    padding: 0 15px;
    justify-content: space-between;
  }
  .item-title{
    font-size: 15px;
    color: #333333;
  }
  .item-icon{
    width: 16px;
    margin-right: 15px;
  }
  .more{
    flex: none;
    width: 7px;
    height: 7px;
    border-top: 1px solid #333;
    border-right: 1px solid #333;
    transform: rotate(45deg);
    margin-left: 14px;
  }
}
.right-cont {
  text-align: right;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  .time{
    color: #999999;
    font-size: 15px;
  }
}
</style>